const zh={
	jiantizhongwen:'简体中文',
    xianlu:'线路',
    dianwei:'当前在线',
    wodezichan:'我的资产',
    touzikuaixun:'投资快讯',
    jiaoyiyonghu:'用户',
    jiaoyipinglei:'交易品类',
    touzipinlei:'项目',
    danlunhuoli:'单轮获利',
    jiaoyiriqi:'交易日期',
    dengluhouchakan:'登陆后查看',
    shouye:'首页',
    jiaoyidating:'交易大厅',
    grzx:'个人中心',
    yucun:'预存',
    zoushi:'峰值',
    wode:'我的',
    zuiditouzi:'最低投资',
    zuidijinru:'最低进入',
    jichulicai:'基础理财',
    zishenlicai:'高级仓',
    dashilicai:'大师理财',
    zhuanjialicai:'专家理财',
	基础理财:'基础理财',
    高级仓:'高级仓',
    大师理财:'大师理财',
    专家理财:'专家理财',
    huiyuanquanyi:'会员权益',
    zuoriyingli:'昨日收益',
    zongzichan:'总资产',
    chucunjin:'储存金',
    jinriyingli:'今日收益',
    zhuanchu:'赎回',
    cunru:'存入',
    zhanghuxinxni:'安全中心',
    lishizhangdan:'历史账单',
    zijinminxi:'资金明细',
    renwuzhongxin:'APP下载',
    xiaoxigonggao:'消息公告',
    tuijianfenxiang:'推荐分享',
    huodongzhongxin:'优惠活动',
    jibenziliao:'基本资料',
    dengluzhanghao:'登录账号',
    nicheng:'昵称',
    shezhi:'设置',
    genggaixianlu:'更改线路',
    guanyuwomen:'关于我们',
    xitongshezhi:'系统设置',
    yuyanshezhi:'语言设置',
    tuichudenglu:'退出登录',
    bangdingyinhangka:'绑定银行卡',
    bangdingxuniqianbao:'绑定虚拟钱包',
    anquanma:'安全码',
    xiugailenglumima:'修改登陆密码',
    yinhangkaguanli:'银行卡管理',
    tianjiayinhangka:'添加银行卡',
    xuniqianbaoguanli:'虚拟钱包管理',
    tianjiaxuniqianbao:'添加虚拟钱包',
    chiakren:'持卡人',
    yinhangkakahao:'银行卡卡号',
    yinhangmingcheng:'银行名称',
    yhkTps:'请绑定用户本人的银行卡',
    nixiqianbaodizhi:'虚拟钱包地址',
    xuniqianbaoleixing:'虚拟钱包类型',
    xnqbTps:' 温馨提示：请填写USDT地址类型，如：trc20、erc20、omni ',
    plchikaren:'请输入持卡人',
    plkahao:'请输入持卡人卡号',
    plyinhangmingcheng:'请输入银行名称',
    plqbdz:'请输入虚拟钱包地址',
    plqblx:'请输入虚拟钱包类型',
    shezhianquanma:'设置安全码',
    yuananquanma:'原安全码',
    xinanquanma:'新安全码',
    planquanma:'请输入原安全码',
    plxinanquanma:'請输入新安全码',
    queren:'确认',
	quxiao:'取消',
    anquanmaTps:' 请设置安全码，请勿与银行卡密码相同',
    anquanmaTpsTow:' 尊敬的客户，此安全码是您转出时的密码，为了安全起见，请尽量不要与登录密码相同',
    xiugaidenglu:'修改登录密码',
    jiumima:'旧密码',
    xinmima:'新密码',
    querenmima:'确认密码',
    pljiumima:'请输入旧密码',
    plxinmima:'请输入新密码',
    plquerenmima:'请再次输入密码',
    wancheng:'完成',
    kaishitouzi:'开始投资',
    kaiTps:'当前线路无法使用时，可尝试切换其他线路',
    zuiyou:'最优',
    dangaqianxianlu:'当前线路',
    dangqianbanben:'当前版本',
    banquansuoyou:'版权所有',
    yinglizhendong:'盈利震动',
    gensuixitong:'跟随系统',
    zhuanchujine:'转出金额',
    plzhuanchu:'请输入转出金额',
    zhuanchuTps:'温馨提示：如有疑问请联系平台客服。',
    xuanzeyh:'提款银行卡',
    xuanzehb:'请选择提款虚拟钱包',
    tijiaoshenqing:'提交申请',
    shuruanquanma:'请输入安全码',
    xianshangcunru:'线上存入',
    saomacunru:'USDT',
    wangyincunru:"银行存入",
    shijian:'时间',
    upshijian:'开始时间',
    downshijian:'结束时间',
    wuTps:'暂无相关数据~',
    jiazaicx:'重新加载',
    di:'第',
    lun:'轮',
    weiyingli:'未盈利',
    yishouli:'已受理',
    zhuangtai:'状态',
    chongzhi:'重置',
    riqi:'日期',
    CapActive:{
        chongzhi:'存入',
        tixian:'赎回',
        goumai:'购买',
        yingli:'盈利',
        zengsong:'彩金',
        weitongguo:'审核中',
        yitongguo:'已通过',
        yijujue:'已拒绝',
    },
    zhanneixiaoxi:"站内消息",
    pingtaigonggao:'平台公告',
    fuzhilanjie:"复制链接",
    denglu:'登录',
    zhuce:'注册',
    jizhumima:'记住密码',
    wangjimima:'忘记密码',
    youkedenglu:'游客登陆',
    zhucezhanghao:"注册账户",
    plusername:'请输入用户名',
    plpassword:'请输入密码',
    plindetifycode:'请输入验证码',
    indetifycodeError:'验证码不正确',
    wanchengzhuce:'完成注册',
    yiyouzhanghao:'已有账户',
    qingdenglu:'请登录',
    remenhuati:'热门话题',
    news1:'原创',
    news2:'推荐',
    news3:'财富',
    renliulan:'人浏览',
    gongnengjieshao:'功能介绍',
    hezhi:'和值',
    lunshu:'轮次',
    jiaoyilunci:'轮号',

    做高:'做高',
    做低:'做低',
    做多:'做多',
    做空:'做空',
    高多:'高多',
    高空:'高空',
    低多:'低多',
    低空:'低空',
	极阴:'极阴',
	极阳:'极阳',
    qi:'轮',
    juli:'距',
    lunjiezhi:'轮',
    yifengpan:'已封盘',
    yiguanbi:'已关闭',
    yonghu:'用户',
    kongbai:'  ',
    danlunjiaoyi:'金额',
    caozuo:'操作',
    pljoin:'加入房间成功',
    pltingshou:'产品已停售',
    gengou:"跟购",
	quedinggengou:"确定跟购吗",
	wanjia:"玩家",
	leibie:"类别",
	jine:"金额",
    gouru:'购入',
    zanweigouru:'暂未购入',
    qigou:'起购',
    gourushuliang:'购入数量',
    changgui:'常规',
    shuzi:'极值',
    yinglihezhi:'盈利和值',
    shouyijieshao:'收益介绍',
    lijicunru:'立即存入',
    kaihaojieguo:'开号结果',
    kaihaoqushi:'开号趋势',
    chanpingshuoming:'最新活动',
    qingchuhuancun:'清除缓存',
    youke:'游客',
	gongxi:'恭喜 ',
	zai:' 在 ',
	yingli:' 盈利 ',
	xiadanchenggong:'下单成功',
	jiazaizhong:'加载中...',
	guanbitg:'已封盘，停止购入',
	xiangoudl:'只能跟购当前轮',
	liaotiantip:'系统已禁言，请选择购入',
	tishi:'提 示',
	zhidao:'确定',
	zhuanchusb:'转出失败',
	zhuanchusbs:'余额不足，转出失败',
	buyxq:'购入详情',
	zdxq:'账单详情',
	orderNo:'账单编号',
	plan_name:'厅房信息',
	order_name:'操作类型:',
	order_money_before:'操作前金额:',
	order_money:'操作金额:',
	order_money_end:'操作后金额:',
	order_status:'操作状态:',
	order_mark:'操作备注:',
	order_time:'操作时间:',
	buyxx:'购入信息',
	haoxx:'选号详情',
	buysj:'购入时间',
	yilou:'遗漏',
	fzjqb:'已复制到粘贴板',
	chanpin1:'最新活动更新中...',
	chanpin2:'详情可联系在线客服',
	Tips:{
        wjmm:'忘记密码请联系客服修改!',
        dlcg:'登录成功',
        tjcg:'提交成功',
        zanwu:'暂无数据',
        buyizhi:'两次密码不一致',
        zcbz:'您的资产不足请存入',
        pltxje:'请输入赎回金额',
        zdtx:'最低赎回100',
		youke:'尊敬的游客您好：请您注册正式会员后操作。',
		szcg:'设置成功',
    },
}
export default zh;