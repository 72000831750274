<template>
  <div class="aticleDetail">
    <header>
      <div class="g-header" style="background: #df1412">
        <div class="left" @click="changego">
          <van-icon name="arrow-left" color="#fff" size="20px" />
        </div>
        <div class="middle fs-16">{{item.title?item.title:'新闻页'}}</div>
        <div class="right">
          <div class="btn-save fs-16"></div>
        </div>
      </div>
      <div class="blockHeight"></div>
    </header>
    <div :style="{'max-height': this.Height + 'px' }"
    style="overflow-y:scroll;"  class="g-conten flex-column" v-html="item.content"></div>
  </div>
</template>

<script>
export default {
  name: "AticleDetail",
  components: {},
  data() {
    return {
        item:null,
        Height:""
    };
  },
  methods: {
    changego() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.Height = document.documentElement.clientHeight;
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight;
    };
  },
  created() {
    let news = this.$route.query.news;
    this.item=JSON.parse(news)
    console.log();
  },
};
</script>
<style lang="less" scoped>
.moreNews {
  height: 100%;
}

.g-conten {
  background-color: #edebf1;
}
</style>