<template>
  <div class="aboutDetail">
    <div class="settingwarp">
      <header>
        <div class="g-header" style="background: #df1412">
          <div class="left" @click="changego">
            <van-icon name="arrow-left" color="#fff" size="20px" />
          </div>
          <div class="middle fs-18">{{ $t('gongnengjieshao') }}</div>
          <div class="right">
            <div class="bg-icon bg-icon-setting"></div>
          </div>
        </div>
        <div class="blockHeight"></div>
      </header>
      <div class="g-content">
        <div v-if="type == '2'" class="title">
          {{ ' 修复了一些已知问题。' }}
        </div>
        <div v-else-if="type == '1'" class="title">
          {{ ' 更新了《隐私协议保护指引》。' }}
        </div>
        <p class="copyright">{{ $t('banquansuoyou') }}©中国五矿</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutDetail",
  components: {},
  data() {
    return {
      type: null,
    };
  },
  methods: {
    changego() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.type = this.$route.query.type;
  },
};
</script>
<style lang="less" scoped>
.settingwarp {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.active-list {
  padding: 0.2rem;
}

.active-item:not(:last-of-type) {
  margin-bottom: 0.2rem;
}

.title {
  min-height: 0.4rem;
  color: #404040;
  // color: #208dff;
  // color: #f4e42d;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  font-size: 0.43rem;
  text-align: left;
  text-indent: 1em;
}

.copyright {
  position: absolute;
  width: 100%;
  bottom: 0.4rem;
  font-size: 0.35rem;
  color: #404040;
}
.aboutDetail {
  background-color: #edebf1;
}
</style>